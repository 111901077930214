import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './simple-product.scss';

export default function SimpleProduct({ value }) {
  return (
    <div className="product">
      <div className="product__thumbnail">
        <GatsbyImage image={value.thumbnail} alt="" />
      </div>
      <div className="product__name">{value.name}</div>
    </div>
  );
}
