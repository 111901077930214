import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Navigator from '../components/navigator/navigator';
import SimpleProduct from '../components/simple-product/simple-product';

export default function Home({ data }) {
  const websiteTitle = 'Sản phẩm tiêu dùng và làm đẹp an toàn';

  const navigatorItems = [
    { text: 'home', url: '/' },
    { text: 'blog', url: '/blog' },
    {
      text: 'contact me',
      url: 'https://fb.me/ngoctram.nguyen.58118',
      external: true,
    },
  ];

  const products = [
    {
      thumbnail: data.img_lipstick.childImageSharp.gatsbyImageData,
      name: 'Son thỏi Rosy',
    },
    {
      thumbnail: data.img_creamy_lipstick.childImageSharp.gatsbyImageData,
      name: 'Son kem Rosy',
    },
    {
      thumbnail: data.img_lipcare.childImageSharp.gatsbyImageData,
      name: 'Ngọc Dưỡng Ủ Môi',
    },
    {
      thumbnail: data.img_cushion.childImageSharp.gatsbyImageData,
      name: 'Phấn nước Rosy',
    },
    {
      thumbnail: data.img_cleansing_water.childImageSharp.gatsbyImageData,
      name: 'Tẩy trang Rosy',
    },
    {
      thumbnail: data.img_purifying_milk.childImageSharp.gatsbyImageData,
      name: 'Sữa rửa mặt Rose Beauty',
    },
    {
      thumbnail: data.img_skin_cream.childImageSharp.gatsbyImageData,
      name: 'Kem body Rosy',
    },
    { thumbnail: data.img_mask.childImageSharp.gatsbyImageData, name: 'Rosy Mask' },
  ];

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Ngọc Trâm Store - Sản phẩm tiêu dùng và làm đẹp an toàn</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Sản phẩm tiêu dùng và làm đẹp an toàn." />
        <link rel="canonical" href="https://www.ngoctramstore.com" />
      </Helmet>
      <header className="header">
        <div className="pure-g">
          <div className="pure-u-1">
            <Navigator value={navigatorItems} />
          </div>
        </div>
      </header>
      <div id="content" className="content">
        <div className="pure-g">
          <div className="pure-u-1">
            <h1 className="website-title">{websiteTitle}</h1>
          </div>
        </div>
        <div className="pure-g">
          {products.map((product, index) => {
            return (
              <div key={index} className="pure-u-1-2 pure-u-md-1-3">
                <SimpleProduct value={product} />
              </div>
            );
          })}
        </div>
      </div>
      <footer id="footer">
        <div className="pure-g">
          <div className="pure-u-1">
            <hr />
            <ul className="info">
              <li className="info__item">Since 2020</li>
              <li className="info__item">
                <a
                  href="https://fb.me/ngoctram.nguyen.58118"
                  className="info__link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  fb.me/ngoctram.nguyen.58118
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export const pageQuery = graphql`
  query {
    img_cleansing_water: file(relativePath: { eq: "images/product-thumbnails/thumbnail_cleansing_water.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
    img_creamy_lipstick: file(relativePath: { eq: "images/product-thumbnails/thumbnail_creamy_lipstick.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
    img_cushion: file(relativePath: { eq: "images/product-thumbnails/thumbnail_cushion.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
    img_lipcare: file(relativePath: { eq: "images/product-thumbnails/thumbnail_lipcare.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
    img_lipstick: file(relativePath: { eq: "images/product-thumbnails/thumbnail_lipstick.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
    img_mask: file(relativePath: { eq: "images/product-thumbnails/thumbnail_mask.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
    img_purifying_milk: file(relativePath: { eq: "images/product-thumbnails/thumbnail_purifying_milk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
    img_skin_cream: file(relativePath: { eq: "images/product-thumbnails/thumbnail_skin_cream.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
    }
  }
`;
